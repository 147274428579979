import React from 'react';
import VisuallyHidden from '../VisuallyHidden';
import * as Icon from 'react-bootstrap-icons';
import { NavLink } from 'react-router-dom';

function Footer({ ...props }) {
	return (
		<footer {...props}>
			<div className="d-flex gap-3 flex-wrap justify-content-center align-items-start">
				<NavLink className="flex-shrink-1 App-link" to="/contact">
					<Icon.Envelope icon="envelope" />
					<VisuallyHidden>Contact Me</VisuallyHidden>
				</NavLink>
				<a className="flex-shrink-1 App-link" href="https://github.com/pjmdesi" target={'_blank'} rel="noopener noreferrer" style={{ color: 'var(--github-white)' }}>
					<Icon.Github icon="GitHub Icon" />
					<VisuallyHidden>GitHub</VisuallyHidden>
				</a>
				<a
					className="flex-shrink-1 App-link"
					href="https://www.linkedin.com/in/pj-mullen/"
					target={'_blank'}
					rel="noopener noreferrer"
					style={{ color: 'var(--bluesky-blue)' }}>
					<svg fill="none" viewBox="0 0 64 57" width="28" style={{width: 'auto', height: '24'}}>
						<path
							fill="#0085ff"
							d="M13.873 3.805C21.21 9.332 29.103 20.537 32 26.55v15.882c0-.338-.13.044-.41.867-1.512 4.456-7.418 21.847-20.923 7.944-7.111-7.32-3.819-14.64 9.125-16.85-7.405 1.264-15.73-.825-18.014-9.015C1.12 23.022 0 8.51 0 6.55 0-3.268 8.579-.182 13.873 3.805ZM50.127 3.805C42.79 9.332 34.897 20.537 32 26.55v15.882c0-.338.13.044.41.867 1.512 4.456 7.418 21.847 20.923 7.944 7.111-7.32 3.819-14.64-9.125-16.85 7.405 1.264 15.73-.825 18.014-9.015C62.88 23.022 64 8.51 64 6.55c0-9.818-8.578-6.732-13.873-2.745Z"
							style={{fill: '#0085ff'}}></path>
					</svg>
					<VisuallyHidden>BlueSky Icon</VisuallyHidden>
				</a>
				<a
					className="flex-shrink-1 App-link"
					href="https://www.linkedin.com/in/pj-mullen/"
					target={'_blank'}
					rel="noopener noreferrer"
					style={{ color: 'var(--linkedin-blue)' }}>
					<Icon.Linkedin icon="LinkedIn Icon" />
					<VisuallyHidden>LinkedIn</VisuallyHidden>
				</a>
				<a className="flex-shrink-1 App-link" href="https://twitch.tv/tectix0" target={'_blank'} rel="noopener noreferrer" style={{ color: 'var(--twitch-purple)' }}>
					<Icon.Twitch icon="Twitch Icon" />
					<VisuallyHidden>Twitch</VisuallyHidden>
				</a>
				<a className="flex-shrink-1 App-link" href="https://youtube.com/@tectix0" target={'_blank'} rel="noopener noreferrer" style={{ color: 'var(--youtube-red)' }}>
					<Icon.Youtube icon="YouTube Icon" />
					<VisuallyHidden>YouTube</VisuallyHidden>
				</a>
				<a className="flex-shrink-1 App-link" href="https://reddit.com/u/tectix0" target={'_blank'} rel="noopener noreferrer" style={{ color: 'var(--reddit-orange)' }}>
					<Icon.Reddit icon="Reddit Icon" />
					<VisuallyHidden>Reddit</VisuallyHidden>
				</a>
				<a className="flex-shrink-1 App-link" href="https://reddit.com/u/tectix0" target={'_blank'} rel="noopener noreferrer" style={{ color: 'var(--buymeacoffee-yellow)' }}>
					<Icon.CupHotFill icon="Coffee Cup" />
					<VisuallyHidden>Buymeacoffee</VisuallyHidden>
				</a>
			</div>
			<script
				type="text/javascript"
				src="https://cdnjs.buymeacoffee.com/1.0.0/button.prod.min.js"
				data-name="bmc-button"
				data-slug="pjm"
				data-color="#FFDD00"
				data-emoji=""
				data-font="Bree"
				data-text="Buy me a coffee"
				data-outline-color="#000000"
				data-font-color="#000000"
				data-coffee-color="#ffffff"></script>
		</footer>
	);
}

export default Footer;
