import React from 'react'
import TrellixWebsite from '../components/Portfolio/TrellixWebsite'
import TestTile from '../components/Portfolio/TestTile'

const portfolio = {
    trellixWebsite: {
        title: 'Trellix Website',
        url: '/portfolio/trellix-website',
        component: <TrellixWebsite />,
    },
    testTile: {
        title: 'Test Tile',
        url: '/portfolio/test-tile',
        component: <TestTile />,
    },
}

export default portfolio