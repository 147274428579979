import React from 'react';

import * as Icon from 'react-bootstrap-icons';
import { Button } from 'react-bootstrap';

const projectButtons = {
	Websites: [
		{
			label: 'Trellix',
			url: 'https://trellix.com',
		},
		{
			label: "XDR Quiz",
			url: "https://www.trellix.com/about/xdr-evolution/",
		},
		{
			label: 'Forge Laser',
			url: 'https://forgelaser.com',
		},
		{
			label: 'PSA Self Storage',
			url: 'https://psaselfstorage.com',
		},
		{
			label: 'Scriptivism',
			url: 'https://scriptivism.pjm.design',
		},
		{
			label: 'Benefits Experience',
			url: 'https://www.figma.com/proto/RsKcFnKGGBfhVAwWiN02vC/Financial-Company-Virtual-Benefits-Experience?page-id=0%3A1&node-id=264-3216&starting-point-node-id=264%3A3216&mode=design&t=hAghWNMxfghu7xAN-1',
		},
		{
			label: 'MRK Investigations',
			url: 'https://mrkinvestigations.org/',
		},
	],
	Apps: [
		{
			label: 'PartnerClock',
			url: 'https://www.figma.com/proto/blq59PvHbJVtemyN8FKyB7/PartnerClock-Redesign?page-id=0%3A1&type=design&node-id=102-5872&viewport=1585%2C760%2C0.19&t=XgskI1wfELNATC7l-1&scaling=min-zoom&starting-point-node-id=102%3A5872&mode=design',
		},
		{
			label: 'Beautify Text',
			url: require('../../img/bt-screenshot-1.png'),
		},
		{
			label: 'Service App',
			url: 'https://www.figma.com/proto/e3wzabAtbWr8Pa8mLvBNx0/SA-Team---Original?page-id=103%3A13055&type=design&node-id=126-19262&viewport=820%2C431%2C0.55&t=9IIDKqahN0NqLjz5-1&scaling=scale-down&starting-point-node-id=126%3A19262&mode=design',
		},
		{
			label: 'Client Portal',
			url: 'https://www.figma.com/proto/fqfCcBAPYHMym1tra2Ie16/Client-Portal-(MINE)?page-id=2%3A9905&type=design&node-id=4-1060&viewport=671%2C496%2C0.34&t=Lm260PaGa2GqCZH6-1&scaling=min-zoom&starting-point-node-id=4%3A1060&mode=design',
		},
		{
			label: 'Locator App',
			url: 'https://www.figma.com/file/jhnOf4Yy0KrsMhKWwUkNpB/Pinpoint-App---Mobile?type=design&node-id=0%3A1&mode=design&t=VhL5BbPFs8R0OuQC-1',
		},
		{
			label: 'Wordle Clone',
			url: 'https://wordgame.pjm.design/',
		},
	],
	Files: [
		{
			label: 'Brandon Cale Font',
			url: require('../../files/fonts/brandon-cale-font.zip'),
		},
		{
			label: 'Felled Blocks Font',
			url: require('../../files/fonts/felled-blocks-font.zip'),
		},
		{
			label: 'Sparcey Font',
			url: require('../../files/fonts/sparcey-font.zip'),
		},
	],
};

function UnderConstructionScreen() {
	return (
		<>
			<h2>Projects</h2>
			{Object.keys(projectButtons).map((category, index) => {
				const buttons = projectButtons[category];

				return (
					<div key={`category-${category}-${index}`}>
						<h3>{category}</h3>
						<div className="d-flex gap-3 flex-wrap mb-4 justify-content-center justify-content-lg-start align-items-start">
							{buttons.map(({ label, url }, index) => {
								return (
									<Button key={index} variant={category === 'Files' ? 'secondary' : 'primary'} href={url} target={'_blank'} rel="noopener noreferrer">
										{label}
										{category === 'Files' ? <Icon.Download /> : <Icon.BoxArrowUpRight />}
									</Button>
								);
							})}
						</div>
					</div>
				);
			})}
		</>
	);
}

export default UnderConstructionScreen;
