import React from 'react';

function ErrorPage() {
	return (
		<div className='col-12 d-flex flex-column align-items-center justify-content-center h-100'>
			<h1>404</h1>
			<h2>Page Not Found</h2>
		</div>
	);
}

export default ErrorPage;
