import React from 'react'
import Home from '../components/Home'
import PrivacyPolicy from '../components/PrivacyPolicy'
import UnderConstructionScreen from '../components/UnderConstructionScreen'
import ContactPage from '../components/ContactPage/ContactPage'
import AboutMe from '../components/AboutMe/AboutMe'
import Portfolio from '../components/Portfolio/Portfolio'

const navigation = {
    home: {
        hideInSideBar: true,
        title: 'Home',
        url: '/',
        component: <Home />,
    },
    portfolio: {
        hideInSideBar: true,
        title: 'Portfolio',
        url: '/portfolio',
        component: <Portfolio />,
    },
    services: {
        hideInSideBar: true,
        title: 'Services',
        url: '/services',
        component: <UnderConstructionScreen />,
    },
    about: {
        title: 'About Me',
        url: '/about',
        component: <AboutMe />,
    },
    contact: {
        title: 'Contact',
        url: '/contact',
        component: <ContactPage />,
    },
    privacyPolicy: {
        title: 'Privacy Policy',
        url: '/privacy-policy',
        component: <PrivacyPolicy />,
        style: 'subdued',
    },
}

export default navigation