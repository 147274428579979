import React from 'react';
import { NavLink } from 'react-router-dom';

import * as Icon from 'react-bootstrap-icons';
import PjmDesignLogo from '../PjmDesignLogo';
import VisuallyHidden from '../VisuallyHidden';
import Footer from '../Footer';

import navigation from '../../data/navigation';

import resumePDF from '../../files/pj-mullen-resume-2025-web.pdf';

function MainNavigation() {

	return (
		<nav role='navigation'>
			<ul>
                {/* Automatic entries from /data/navigation.js ↓ */}
				{Object.keys(navigation).map(key => {
                    let linkStyle = navigation[key].style ? ` ${navigation[key].style}` : '';
					return (
						!navigation[key].hideInSideBar && <li key={key} className='no-marker m-0'>
							<NavLink to={navigation[key].url} className={linkStyle}>{navigation[key].title}</NavLink>
						</li>
					);
				})}
                {/* Manual Entries ↓ */}
                <li className="no-marker m-0">
                    <a className='subdued' href={resumePDF} target="_blank" rel="noopener noreferrer" download>Resume <Icon.FileEarmarkPerson /></a>
                </li>
			</ul>
		</nav>
	);
}

function SideBar() {
	return (
		<aside>
			<NavLink to="/" className="site-logo text-primary h1">
				<PjmDesignLogo />
				<VisuallyHidden>
                    <h1>PJM Design</h1>
                </VisuallyHidden>
			</NavLink>
			<MainNavigation />
            <Footer id="desktopFooter"/>
		</aside>
	);
}

export default SideBar;