import React from 'react';

const TextInput = ({ label, required, type }) => {
    const [value, setValue] = React.useState('');

    const handleChange = (e) => {
        setValue(e.target.value);
    }

    return (
        <label className={'input-container' + (value.length ? ' entry-present' : '')}>
            <span className="label-text">{label}</span>
            <input type={type} placeholder={label + (required ? ' *' : '')} required={required} onChange={handleChange} />
        </label>
    );
};

export default TextInput;