import React from 'react';

function Textarea({ label, required, rows=3 }) {
    const [value, setValue] = React.useState('');

    const handleChange = (e) => {
        setValue(e.target.value);
    }

	return (
		<label className={'input-container' + (value.length ? ' entry-present' : '')}>
            <span className="label-text">{label}</span>
			<textarea name={label} placeholder={label + (required ? ' *' : '')} value={value} onChange={handleChange} rows={rows} />
		</label>
	);
}

export default Textarea;
