import React from 'react';

function PrivacyPolicy() {
	return (
		<div id="privacyPolicy">
			<h1>
				Privacy Policy for PJM Design <small>(https://pjm.design)</small>
			</h1>
			<ol>
				<li>
					<h2>Introduction</h2>
					<p>
						We respect your privacy and are committed to protecting your personal data. This privacy policy will inform you about how we look after your personal data
						when you visit our website (regardless of where you visit it from) and tell you about your privacy rights and how the law protects you.
					</p>
				</li>
				<li>
					<h3>Who is responsible for your data?</h3>
					<p>
						PJM Design, based in Dallas, Texas, is the data controller and responsible for your personal data (collectively referred to as "PJM Design," "we," "us," or
						"our" in this privacy policy).
					</p>
				</li>
				<li>
					<h3>Personal Data We Collect About You</h3>
					<p>
						Personal data, or personal information, means any information about an individual from which that person can be identified. It does not include data where
						the identity has been removed (anonymous data). We may collect, use, store, and transfer different kinds of personal data about you, which we have grouped
						together as follows:
					</p>
					<ul className="mb-3">
						<li>Identity Data includes first name, last name, username, or similar identifier.</li>
						<li>
							Contact Data includes billing address, delivery address, email address, telephone number, and any other information provided by the customer for
							communication purposes.
						</li>
						<li>
							Technical Data includes internet protocol (IP) address, your login data, browser type and version, time zone setting and location, browser plug-in types
							and versions, operating system and platform, and other technology on the devices you use to access this website.
						</li>
						<li>Usage Data includes information about how you use our website.</li>
					</ul>
				</li>
				<li>
					<h3>How We Use Your Personal Data</h3>
					<p>
						We will only use your personal data when the law allows us to. Most commonly, we will use your personal data in the following circumstances:
					</p>
                        <ul>
                            <li>Where we need to perform the contract we are about to enter into or have entered into with you.</li>
                            <li>Where it is necessary for our legitimate interests (or those of a third party) and your interests and fundamental rights do not override those interests.</li>
                            <li>Where we need to comply with a legal obligation.</li>
                        </ul>
				</li>
				<li>
					<h3>Data Security</h3>
					<p>
						We have put in place appropriate security measures to prevent your personal data from being accidentally lost, used or accessed in an unauthorized way,
						altered, or disclosed.
					</p>
				</li>
				<li>
					<h3>Data Retention</h3>
					<p>
						We will only retain your personal data for as long as necessary to fulfill the purposes we collected it for, including for the purposes of satisfying any
						legal, accounting, or reporting requirements.
					</p>
				</li>
				<li>
					<h3>Your Legal Rights</h3>
					<p>
						Under certain circumstances, you have rights under data protection laws in relation to your personal data, including the right to request access,
						correction, erasure, restriction, transfer, to object to processing, to portability of data, and (where the lawful ground of processing is consent) to
						withdraw consent.
					</p>
				</li>
				<li>
					<h3>Contact Us</h3>
					<p>
						If you have any questions about this privacy policy or our privacy practices, please <a href="/contact/">contact us</a>.
					</p>
				</li>
			</ol>
		</div>
	);
}

export default PrivacyPolicy;
