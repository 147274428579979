import React from 'react';

function PortfolioTile({ ...props }) {
	return (
		<div className={`${props.className} shadow portfolio-tile rounded-3 position-relative overflow-hidden`} style={{backgroundImage: `url(${props.portfolioItem.image})`}}>
			<h6 className='position-absolute bottom-0 col-12 bg-black p-2 m-0'>{props.portfolioItem.title}</h6>
		</div>
	);
}

export default PortfolioTile;
