import React from 'react';
import profilePic from '../../img/profile.jpg';

function AboutMe() {
	return (
		<div className="text-start">
			{/* <h1>H1 Header</h1>
            <h2>H2 Header</h2>
            <h3>H3 Header</h3>
            <h4>H4 Header</h4>
            <h5>H5 Header</h5>
            <h6>H6 Header</h6> */}

			<img src={profilePic} alt="PJ Mullen" className="profile-image col-5 mb-4 rounded-circle" style={{maxWidth: "450px"}} />

			<h1>Hi there,</h1>
			<p>My name is PJ. I'm interested in too many things: from music composition to particle physics.</p>
			<p>
				In college (2012–2016) I studied mathematics & physics before switching to "digital design" having recognized my seemingly natural skill for it and considering my
				future career path more seriously. Since, I continue to delve deep into web development while continuing to learn and develop my skills in music, animation,
				mathematics, physics, and my many other interests.
			</p>
			<p>
				I call myself a <i>digital designer</i> because basically everything I make involves a computer. My favorite kind of work is 3D graphics and animation, but my most
				lucrative has been UI/UX and Web Development.
			</p>
			<h2>Work</h2>
			<h3>Trellix</h3>
			<p>
				My main source of income is as a web developer for Trellix, an enterprise security company that was spun off from McAfee in 2021. There, I design & manage the
				customer-facing website under the marketing arm. My work is frequently seen by hundreds of thousands of users. Featured projects include Biannual Threat Reports —
				which are breakdowns and analyses of the cybersecurity threat landscape over the previous 2 quarters.
			</p>
			<h3>Other</h3>
			<p>I assist family and other people I know by designing and maintaining websites, usually for their small business or organizations.</p>
			<p>
				I also lend my dev skills to friends and communities, such as{' '}
				<a href="https://github.com/GreySole/Spooder" target="_blank" rel="noopener noreferrer">
					GreySole's Spooder
				</a>{' '}
				(a skeleton that makes building bots and stream features easier and distributable), <a href="https://beta.gamepadviewer.com/gamepads?size=5&page=1" target='_blank' rel='noopener noreferrer'>GamePadViewer</a> (controller overlays for game streamers), and many more.
			</p>
			<h2>Life</h2>
			<p>
				I watch a lot of TV & movies, and I play a lot of video games and some board games. My current gaming obsession is RimWorld,
				which I can binge for a good 10 hours in one session. I have an amazing Partner named Brandon who I just can't seem to spend enough time with. He loves plants, food, architecture, design, and
				fashion. He has opened my eyes to many beautiful things.
			</p>
			<h3>Gamer / Streamer</h3>
			<p>
				As is true for many people these days, I am also a gamer and I happen to stream these games occasionally. Catch me on{' '}
				<a href="https://twitch.tv/tectix0" target={'_blank'} rel="noopener noreferrer" style={{ color: 'var(--twitch-purple)' }}>
					Twitch
				</a>{' '}
				or{' '}
				<a href="https://youtube.com/@tectix0" target={'_blank'} rel="noopener noreferrer" style={{ color: 'var(--youtube-red)' }}>
					YouTube
				</a>
				!
			</p>
			<p>Please consider donating to support my ongoing projects!</p>
			<script
				type="text/javascript"
				src="https://cdnjs.buymeacoffee.com/1.0.0/button.prod.min.js"
				data-name="bmc-button"
				data-slug="pjm"
				data-color="#FFDD00"
				data-emoji=""
				data-font="Cookie"
				data-text="Buy me a coffee"
				data-outline-color="#000000"
				data-font-color="#000000"
				data-coffee-color="#ffffff"></script>
			<a href="https://www.buymeacoffee.com/pjm" target="_blank" style={{ height: '60px', width: '217px' }} rel='noopener noreferrer' className='align-self-start d-block'>
				<img src="https://cdn.buymeacoffee.com/buttons/v2/default-yellow.png" alt="Buy Me A Coffee" style={{ height: '60px', width: '217px' }} />
			</a>
		</div>
	);
}

export default AboutMe;
