import React from 'react';
import './interactive-canvas.scss';

function InteractiveCanvas() {

    // const canvasRef = React.useRef(null);

    return <canvas id="interactiveCanvas"></canvas>;
}

export default InteractiveCanvas;
