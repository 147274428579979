import React from 'react';
import ContactForm from '../ContactForm';

function ContactPage() {
	return (
		<>
			<h1><small>Reach out using this form</small></h1>
			<ContactForm />
		</>
	);
}

export default ContactPage;
