import React from 'react';
import InputGroup from '../InputGroup';
import TextInput from '../TextInput';
import Textarea from '../Textarea';

function ContactForm() {

	return (
		<form name="contact" method="POST" data-netlify="true" data-netlify-honeypot="bot-field">
            <input type="hidden" name="form-name" value="contact" />
			<InputGroup>
				<TextInput label="Name" type="text" required />
				<TextInput label="Email" type="email" required />
			</InputGroup>
			<InputGroup>
				<Textarea label="Message" required />
			</InputGroup>
			<button className="btn btn-primary btn-lg" type="submit">
				Send
			</button>
		</form>
	);
}

export default ContactForm;
