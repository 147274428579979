import React from 'react';
import PortfolioTile from '../PortfolioTile';
// import * as Icon from 'react-bootstrap-icons';
// import { NavLink } from 'react-router-dom';
import portfolio from '../../data/portfolio';
import Fancybox from '../FancyBox';
import Carousel from '../Carousel';
// import { Thumbs } from '@fancyapps/ui';

function Portfolio() {
	return (
		<div>
			<h1>Portfolio</h1>
			<hr />
			<div className="d-flex flex-wrap gap-3">
				{/* Automatic entries from /data/portfolio.js ↓ */}
				{Object.keys(portfolio).map(key => {
					return (
						<>
							<PortfolioTile className="col-12 col-md-6 col-lg-4 col-xl-3 col-xxl-2" key={`${key}-${portfolio[key].title}`} portfolioItem={portfolio[key]}>
								<h5>{portfolio.title}</h5>
							</PortfolioTile>
						</>
					);
				})}
			</div>
			<p>Portfolio page is under construction.</p>
			<p>Check back soon!</p>
			<div>
				<Fancybox
					// Sample options
					options={{
						Carousel: {
							infinite: false,
						},
					}}>
					<Carousel
						// Sample options
						options={{ infinite: false, Thumbs: false }}>
						<div className="f-carousel__slide" data-fancybox="gallery" data-src="https://lipsum.app/id/60/1600x1200" data-thumb-src="https://lipsum.app/id/60/200x150">
							<img alt="" src="https://lipsum.app/id/60/400x300" width="400" height="300" />
						</div>
						<div className="f-carousel__slide" data-fancybox="gallery" data-src="https://lipsum.app/id/61/1600x1200" data-thumb-src="https://lipsum.app/id/61/200x150">
							<img alt="" src="https://lipsum.app/id/61/400x300" width="400" height="300" />
						</div>
						<div className="f-carousel__slide" data-fancybox="gallery" data-src="https://lipsum.app/id/62/1600x1200" data-thumb-src="https://lipsum.app/id/62/200x150">
							<img alt="" src="https://lipsum.app/id/62/400x300" width="400" height="300" />
						</div>
						<div className="f-carousel__slide" data-fancybox="gallery" data-src="https://lipsum.app/id/63/1600x1200" data-thumb-src="https://lipsum.app/id/63/200x150">
							<img alt="" src="https://lipsum.app/id/63/400x300" width="400" height="300" />
						</div>
						<div className="f-carousel__slide" data-fancybox="gallery" data-src="https://lipsum.app/id/64/1600x1200" data-thumb-src="https://lipsum.app/id/64/200x150">
							<img alt="" src="https://lipsum.app/id/64/400x300" width="400" height="300" />
						</div>
					</Carousel>
				</Fancybox>
			</div>
		</div>
	);
}

export default Portfolio;
