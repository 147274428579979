import React from 'react';
import { Routes, Route } from 'react-router-dom';

import './App.scss';

import SideBar from './components/SideBar';
import Footer from './components/Footer';
import InteractiveCanvas from './components/InteractiveCanvas';

import navigation from './data/navigation';
import ErrorPage from './components/ErrorPage';

const DEV = process.env.NODE_ENV === 'development';

// import * as BootstrapComponents from 'react-bootstrap';
// console.log( BootstrapComponents );

!DEV && console.log(
	`
%c@@@@@@@@@@@@@@@@@@@@@@@  %c000000000000000
 %c@@@@@@@@@@@@@@@@@@@@@  %c000000000000000
  %c@@@             @@@              %c000
   %c@@@           @@@   %c888888888  %c000
    %c@@@         @@@   %c888888888  %c000
     %c@@@       @@@     %c888      %c000
      %c@@@     @@@       %c888    %c000
       %c@@@   @@@   %c888888888  %c000
        %c@@@ @@@   %c888888888  %c000
         %c@@@@@  %c0  %c888      %c000
          %c@@@  %c000  %c888    %c000
           %c@@@  %c000  %c888  %c000
            %c@@@  %c000  %c8  %c000
             %c@@@  %c000   000
              %c@@@  %c000 000
               %c@@@  %c00000
                %c@@@  %c000
                 %c@@@  %c0
                  %c@@@
                   @`,
	'color: #00f;font-family:Monospace;',
	'color: #0f0;font-family:Monospace;',
	'color: #00f;font-family:Monospace;',
	'color: #0f0;font-family:Monospace;',
	'color: #00f;font-family:Monospace;',
	'color: #0f0;font-family:Monospace;',
	'color: #00f;font-family:Monospace;',
	'color: #f00;font-family:Monospace;',
	'color: #0f0;font-family:Monospace;',
	'color: #00f;font-family:Monospace;',
	'color: #f00;font-family:Monospace;',
	'color: #0f0;font-family:Monospace;',
	'color: #00f;font-family:Monospace;',
	'color: #f00;font-family:Monospace;',
	'color: #0f0;font-family:Monospace;',
	'color: #00f;font-family:Monospace;',
	'color: #f00;font-family:Monospace;',
	'color: #0f0;font-family:Monospace;',
	'color: #00f;font-family:Monospace;',
	'color: #f00;font-family:Monospace;',
	'color: #0f0;font-family:Monospace;',
	'color: #00f;font-family:Monospace;',
	'color: #f00;font-family:Monospace;',
	'color: #0f0;font-family:Monospace;',
	'color: #00f;font-family:Monospace;',
	'color: #0f0;font-family:Monospace;',
	'color: #f00;font-family:Monospace;',
	'color: #0f0;font-family:Monospace;',
	'color: #00f;font-family:Monospace;',
	'color: #0f0;font-family:Monospace;',
	'color: #f00;font-family:Monospace;',
	'color: #0f0;font-family:Monospace;',
	'color: #00f;font-family:Monospace;',
	'color: #0f0;font-family:Monospace;',
	'color: #f00;font-family:Monospace;',
	'color: #0f0;font-family:Monospace;',
	'color: #00f;font-family:Monospace;',
	'color: #0f0;font-family:Monospace;',
	'color: #f00;font-family:Monospace;',
	'color: #0f0;font-family:Monospace;',
	'color: #00f;font-family:Monospace;',
	'color: #0f0;font-family:Monospace;',
	'color: #00f;font-family:Monospace;',
	'color: #0f0;font-family:Monospace;',
	'color: #00f;font-family:Monospace;',
	'color: #0f0;font-family:Monospace;',
	'color: #00f;font-family:Monospace;',
	'color: #0f0;font-family:Monospace;',
	'color: #00f;font-family:Monospace;',
	'color: #0f0;font-family:Monospace;',
	'color: #00f;font-family:Monospace;',
);

function App() {
	return (
		<div id="App">
			<SideBar />
			<main>
				<Routes>
                    <Route path="*" element={<ErrorPage />} />
					{Object.keys(navigation).map(key => (
						<Route path={navigation[key].url} exact element={navigation[key].component} key={key} />
					))}
				</Routes>
			</main>
			<Footer id="mobileFooter" />
			<InteractiveCanvas />
		</div>
	);
}

export default App;
